import { useState, useEffect } from 'react'
import { NextSeo } from 'next-seo'
import { connect, useDispatch } from 'react-redux'
import { Button } from '../../components/forms'
import { ROUTE_PATHS } from '../../constants'
import { signIn, signOut, useSession } from 'next-auth/react'
import { revertAll } from '@pages/feature/common/commonAction'

const url = `${process.env.FRONTEND_BASE_URL}${ROUTE_PATHS.HOME}`
const title = 'Boundless Rider | Insurance'
const description = 'Home Page'

const Login = () => {
  const dispatch = useDispatch()
  useEffect(() => {
    if (window.location.pathname == '/login' && check()) {
      clearStorageData()
    }
  }, [window.location.pathname])

  function check() {
    if (localStorage.getItem('policyLocator') !== null) {
      let url = localStorage.getItem('url')
      history.pushState('', '', url)
      return false
    }

    return true
  }

  const clearStorageData = () => {
    localStorage.clear()
    sessionStorage.clear()
    dispatch(revertAll())
    localStorage.setItem('appInitializationFlag', 'true')
  }

  return (
    <>
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        openGraph={{
          url,
          title,
          description
        }}
      />

      <>
        <div className="relative">
          <main className="lg:relative">
            <div className="login-background-image">
              <div className="flex justify-content-center sm:justify-content-end align-items-end login-padding-bottom">
                <div className="flex flex-column justify-content-center login-text-wrapper-position text-center">
                  <div>
                    <p className="text-2xl sm:text-5xl font-Oswald font-semibold text-white">
                      Welcome!
                    </p>
                  </div>
                  <div className="mb-3 pl-1 mt-2">
                    <p className="text-base sm:text-xl sm:font-bold font-Sans font-normal text-white">
                      Access your account
                    </p>
                  </div>
                  <div className="flex flex-column">
                    <div className="flex justify-content-center  text-gray-600 border-round mt-1">
                      <a
                        href={`/signin`}
                        onClick={(e) => {
                          e.preventDefault()
                          signIn('email')
                        }}>
                        <Button
                          type="button"
                          className="my-2 h-10 w-18rem font-bold py-2 px-4 text-white  button-orange-bg">
                          <span className="text-white">Sign in With Email</span>
                        </Button>
                      </a>
                    </div>
                    <div className="flex justify-content-center  text-gray-600 border-round">
                      <a
                        href={`/signin`}
                        onClick={(e) => {
                          e.preventDefault()
                          signIn('phone')
                        }}>
                        <Button
                          type="button"
                          className="my-2 h-10 w-18rem font-bold py-2 px-4 text-white  button-orange-bg">
                          <span className="text-white">
                            Sign in With Phone Number
                          </span>
                        </Button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-column px-3 sm:px-5">
              <div>
                <div>
                  <p className="sm:text-5xl text-color-energy-yellow font-semibold mb-3 mt-6 font-Oswald">
                    RIDE WITH THE FREE BOUNDLESS RIDER APP
                  </p>
                </div>
                <div className="flex flex-column font-Sans mb-3">
                  <p className="sm:text-2xl text-xl font-color-asphalt-gray-60 font-bold mb-3">
                    Time to Explore
                  </p>
                  <span className="sm:text-base text-sm font-color-asphalt-gray-60 font-normal">
                    Real-Time Rider Feedback.
                  </span>
                  <span className="sm:text-base text-sm font-color-asphalt-gray-60 font-normal">
                    {' '}
                    Big-Time Charity Giveback.
                  </span>
                </div>
                <div>
                  <p className="text-sm font-color-asphalt-gray-60 font-normal">
                    Boundless Rider riding app is available as a free download.
                    In addition to providing real-time rider <br /> telematics,
                    the Boundless Rider riding app lets you easily raise money
                    for charity — just by riding!
                  </p>
                </div>
              </div>
            </div>
          </main>
        </div>
      </>
    </>
  )
}

export default connect(null, null)(Login)
